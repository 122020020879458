













import { Component, Vue, Prop } from "vue-property-decorator";
@Component({
  name: "stepsSelBank",
})
export default class extends Vue {
  @Prop({required: true}) private active!: number
  @Prop({default: []}) private stepOps!: string[]
  get isShowStepTitle() {
    return window.screen.availWidth > 768
  }
  created() {
  }
}
