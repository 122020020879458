export const bankRoutes = {
  SCB: 'general',
  BAY: 'general',
  BBL: 'general',
  KBANK: 'general'
}

export const newBankRoutes = {
  SCB: 'newGeneral',
  BAY: 'newGeneral',
  BBL: 'newGeneral',
  KBANK: 'newGeneral',
  UOB: 'newGeneral',
  MAYBANK: 'newGeneral',
}
